.App {
  text-align: left;
  font-family: "Chakra Petch";
  background-color: yellow;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

.label {
  font-size: larger;
  font-weight: bold;
  text-align: right;
}

.rules {
  color: white;
  background-color: magenta;
}

.klass {
  font-family: "Rubik Glitch";
  font-size: 4vw;
  font-weight: bold;
}

a, a:link, a:visited, a:hover, a:active {
  color: inherit;
  text-decoration: inherit;
  font-weight: inherit;
}
